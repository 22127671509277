/* Reset */
body,
div,
h1,
h2,
h3,
p,
ul,
li,
a {
  margin: 0;
  padding: 0;
}

/* Colors */
:root {
  --my-blue-primary: rgb(0, 0, 255);
  --my-blue-primary-hover: rgb(46, 112, 255);
}

/* Body */
body {
  background-color: var(--bs-gray-100);
}

/* Images */
img {
  width: 100%;
}

/* Header */
.header {
  background-color: var(--bs-gray-200);
  border-radius: 1rem;
  padding: 1rem;
  margin: 0 auto;
}

/* Headings */
h1 {
  font-size: 2rem;
  font-weight: bold;
}

h2 {
  color: var(--bs-body-color);
  font-size: 1.5rem;
}

h3 {
  color: var(--my-blue-primary);
  font-size: 1.2rem;
}

/* Links and Buttons */
a,
button {
  text-decoration: none;
  cursor: pointer;
  padding: 0;
}

/* Text */
.description {
  font-size: 1rem;
}

.underlined-span,
.clickable-span {
  text-decoration: underline;
  cursor: pointer;
}

.clickable-span {
  font-size: 1rem;
  color: var(--bs-body-color);
}

.caption {
  font-size: 0.75rem;
  font-weight: light;
}

/* Popups */
.popup-text {
  text-align: left;
  font-size: 0.875rem;
}

.popup-text span {
  color: rgba(0, 0, 0, 0.6);
}

/* Lists */
ul,
li {
  list-style: none;
  list-style-position: outside;
}

/* Footer */
.footer-bg {
  background-color: var(--bs-gray-200);
  margin-bottom: 0;
}

.footer-bg p,
.footer-bg a {
  font-size: 0.8rem;
  text-decoration: none;
  color: var(--bs-gray-600);
}

.footer-bg a:hover,
.footer-bg button:hover {
  color: rgb(0, 0, 0);
}

/* Modals */
body.modal-open {
  overflow: hidden;
}

.open-modal-btn,
.close-modal-btn {
  font-size: 0.8rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.open-modal-btn:hover,
.close-modal-btn:hover {
  stroke: rgb(0, 0, 0);
}

.popup-modal {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.3);
  border-radius: 0.375rem;
  z-index: 100;
  width: 21rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .popup-modal {
    left: 50%;
    transform: translateX(-50%);
  }
}

.popup-modal-header,
.submission-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.popup-modal-header h2 {
  padding-bottom: 0.625rem;
}

.submission-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  background-color: white;
  padding: 1rem 1.5rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.3);
  border-radius: 0.375rem;
  z-index: 200;
  width: 26.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .submission-modal {
    width: 20rem;
  }
}

/* Search Bar */
.search-bar {
  border-bottom: 0.125rem solid var(--bs-gray-200);
  margin-right: 1.5rem;
}

.search-input-icon {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0.125rem;
}

.search-input {
  all: unset;
  width: 100%;
  background-color: transparent;
  text-align: left;
  padding: 0.375rem 0.75rem;
}

/* Buttons */
.toggle-button {
  background-color: #ffffff;
  color: var(--bs-body-color);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 2rem;
  padding: 0.375rem 1rem;
  cursor: pointer;
  text-align: center;
}

.toggle-button:hover,
.toggle-button.active {
  background-color: var(--bs-gray-200);
}

/* Primary Button */
.my-btn-primary {
  font-weight: 600;
  color: #ffffff;
  background-color: var(--my-blue-primary);
  border-color: var(--my-blue-primary);
  border-radius: 0.5rem;
}

.my-btn-primary:hover {
  color: #ffffff;
  background-color: var(--my-blue-primary-hover);
  border-color: var(--my-blue-primary-hover);
}

/* Secondary Button */
.my-btn-secondary {
  font-weight: 600;
  color: var(--bs-gray-600);
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.5rem;
}

.my-btn-secondary:hover {
  color: #000000;
}

/* Miscellaneous */
.collapsible-list-icon {
  vertical-align: middle;
}

/* List item */
/* Links in Lists */
.list-item {
  color: var(--bs-body-color);
  text-decoration: underline;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-item:hover {
  font-weight: 600;
  background-color: #e9ecef;
  padding: 0.25rem 0.5rem;
  transition: 0.25s ease-in-out;
  border-radius: 0.5rem;
}

/* Grid item */

.grid-item {
  background-color: white;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 1rem;
  padding: 1rem 1rem;
}

.grid-item span {
  color: var(--bs-body-color);
  text-decoration: underline;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-item p {
  color: var(--bs-body-color);
  text-decoration: none;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-item:hover span {
  font-weight: 600;
}

.grid-item:hover {
  background-color: #e9ecef;
  transition: 0.25s ease-in-out;
}